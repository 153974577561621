@import "./ThemeVariables.module.scss";

.FullParent {
	width: 100%;
	height: 100%;
}




.Page {
	min-width: 412px;
	min-height: 412px;
	overflow-x: auto;
	overflow-y: auto;
	width: 100vw;
	height: 100vh;
	background-color: var(--Page_Background)
}



.SkillSectionLayout{
	width:80%;
}



.CVSection {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 15px
}






#root {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	background-color: var(--Page_Background)
}


body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	background-color: var(--Page_Background)
}

html {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	font-size: medium;
	background-color: var(--Page_Background)
}
