.SmallMargin{
	margin:15px;
}
.SmallMarginSide {
	margin-left: 15px;
	margin-right: 15px;
}
.Width{
	width:100%;
}
.RightMargin{
	margin-right:15px;
}