
@import url(https://fonts.googleapis.com/css?family=Oswald:400,300);

@import "../../../scss/ThemeVariables.module.scss";


.Stamp {
	width: 200px;
	height: 200px;
	padding: 10px;
	border: 1px solid black;
	background-size: cover;
	background-clip: content-box;

	box-sizing: border-box;
	position: relative;
	margin: auto;
	font: 400 2em/180px 'Baskerville', serif;
	color: #fbfbfb;
	text-indent: 20px;
}
	.Stamp p {
		text-align:center;
	}
.Stamp:after {
	content: "";
	display: block;
	position: absolute;
	border: 50px solid transparent;
	border-bottom: 50px solid var(--Card_Background);
	bottom: -60px;
	right: -65px;
	box-shadow: 0px 7px 6px -9px black;
	transform: rotate(135deg);
}

.Stamp:before {
	content: "";
	display: block;
	position: absolute;
	border: 50px solid transparent;
	border-top: 50px solid var(--Card_Background);
	top: -60px;
	left: -65px;
	box-shadow: 0px -7px 6px -9px black;
	transform: rotate(135deg);
}
.StampContainer {
	--color: green;
	--border-color: blue;
	transform: rotateZ(var(--base-stamp-rot));
	position: fixed;
	right: 5%;
	top: 2%;
	z-index: 2
}

.NewOut {
	background-color: var(--New_Stamp_BorderColor)
}
.NewIn{
	background-color: var(--New_Stamp)
	
}
.CoolOut {
	background-color: var(--Cool_Stamp_BorderColor)
}

.CoolIn {
	background-color: var(--Cool_Stamp)
}