
@import "../../../scss/ThemeVariables.module.scss";
div {
	transform-style: preserve-3d;
}
$count: 5;
$size: 30px;
.container {
	position: relative;
	display: grid;
	grid-template-rows: repeat(20, 3vh);
	grid-template-columns: repeat(20, 3vw);
	transform-style: preserve-3d;

	&:active {
		.monitor {
			transform: scale3d(2,2,2);
		}
	}
}

.monitor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	perspective: 900px;
	transition: 300ms;

	
}



.camera {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	transition: 500ms;
	animation: rotate random(10000) + 500ms cubic-bezier(0.550, 0.055, 0.675, 0.190) infinite alternate;

	&.-x {
		transform: rotateX(-30deg);
	}

	&.-y {
		transform: rotateY(0deg);
	}
}



.panel {
	position: absolute;
	top: calc(50% - #{$size / 2});
	left: calc(50% - #{$size / 2});
	width: $size;
	height: $size;
	background: #fff;
	backface-visibility: hidden;
	// border: 3px double rgba(#000, 0.3);
	box-sizing: border-box;

	&:nth-child(1) {
		transform: rotateX(0deg) translateZ(#{--size / 2});
		background: #2B4865;
	}

	&:nth-child(2) {
		transform: rotateX(180deg) translateZ(#{--size / 2});
		background: #2B4865;
	}

	&:nth-child(3) {
		transform: rotateX(90deg) translateZ(#{--size / 2});
		background: #8FE3CF;
	}

	&:nth-child(4) {
		transform: rotateX(-90deg) translateZ(#{--size / 2});
		background: #001C4C;
	}

	&:nth-child(5) {
		transform: rotateY(90deg) translateZ(#{--size / 2});
		background: #256D85;
	}

	&:nth-child(6) {
		transform: rotateY(-90deg) translateZ(#{--size / 2});
		background: #256D85;
	}
}


.scale {
	transform: scale3d(0.96, 0.96, 0.96);
}

.cube {
	@for $i from 1 through $count * $count * $count {
		&:nth-child(#{$i}) {
			transform: translateX(-$size * ($count - 1) / 2 + $i % $count * $size) translateY(-$size * ($count - 1) / 2 + ceil($i / $count) % $count * $size) translateZ(-$size * ($count + 1) / 2 + ceil($i / ($count * $count)) * $size);

			.scale {
				animation: scale random(1000) + 500ms random(1500) + 0ms cubic-bezier(0.550, 0.055, 0.675, 0.190) infinite alternate;
			}
		}
	}
}

@keyframes scale {
	0% {
		transform: scale3d(0.96, 0.96, 0.96);
	}

	30% {
		transform: scale3d(0.96, 0.96, 0.96);
	}

	90% {
		transform: scale3d(0.1, 0.1, 0.1);
	}

	100% {
		transform: scale3d(0.1, 0.1, 0.1);
	}
}
.Cubes {
	transform-style: preserve-3d;
		width: 40%;
		height: 40%; 
		max-height: 40%;
		min-height:40%; 
		position:fixed;
		transform: translate(0,2500%);
}
@keyframes rotate {
	0% {
		transform: rotateY(0deg) rotateX(0deg) scale3d(0, 0, 0);
	}
	10% {
		transform: rotateY(90deg) rotateX(90deg) scale3d(0.5, 0.5, 0.5);
	}

	40% {
		transform: rotateY(180deg) rotateX(180deg) scale3d(1, 1, 1);
	}
	60% {
		transform: rotateY(240deg) rotateX(240deg);
	}
	90% {
		transform: rotateY(270deg) rotateX(270deg) scale3d(0.5, 0.5, 0.5);
	}

	100% {
		transform: rotateY(0deg) rotateX(0deg) scale3d(0, 0, 0);
	}
}