@import "./ThemeVariables.module.scss";

.CompetenceContainer {
	padding: 5px;
	justify-items: flex-start;
	flex-direction: row;
	display: flex;
	align-items: center
}

.CVPageContainer {
	width: 90%;
	height: 90%;
	padding:10px;
}

.ContactContainer {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-around;
	height: 90%;
	width: 100%
}

.CVSectionContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	flex-flow: row wrap
}

.TechContainer {
	margin: 5px;
	background-color: var( --Card_Background);
}

.SettingContainer{
	height:fit-content;
	width:40%;
}

.ButtonContainer {
height:fit-content;
justify-content:space-evenly;
margin-top:10px;
}
