
@import "../../../../scss/ThemeVariables.module.scss";
.Level {
	width: 15px;
	height: 7px;
	border-bottom: solid var(--small-border-color) var(--border-size);
	border-top: solid var(--small-border-color) var(--border-size);
	border-left: solid var(--small-border-color) var(--border-size);
	display: inline-block;
}
.BasicLevel {
	background-color: var(--competence-basic-color);
}
.MediumLevel {
	background-color: var(--competence-medium-color);
}
.GoodLevel {
	background-color: var(--competence-good-color);
}

.ExcellentLevel {
	background-color: var(--competence-excellent-color);
}

.EmptyLevel {
  background-color: transparent;
}

.CompetenceContainer {
  margin-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.CompetenceContainer :last-child {
	border-right: solid var(--small-border-color) var(--border-size);
	border-radius: 0px 5px 5px 0px;
}

.CompetenceContainer :first-child {
  border-radius: 5px 0px 0px 5px;
}

.CompetenceCard {
  width: fit-content;
  height: fit-content;
  margin: 2px;
  display: flexbox;
}


.Competence {
  font-size: small;
  margin-left: 4px;
}

.CompetenceWrapper {
  display: inline-flex;
  height: auto;
  justify-content: flex-start;
}
