.FadeAnim2Sec {
	animation:fade 2s ease-in
}
.RightAnim2Sec {
	animation: fadeInRight 2s linear
}

@keyframes fadeInRight {
	0% {
		transform: translate(300%,0px) ;
		opacity:0
	}
	10% {
		transform: translate(300%,0px) ;
		opacity:1
	}
	30%{
		transform:translate(0px,0px)
	}
	50%{
		transform:translate(50px,0px)
	}
	100%{
		transform: translate(0px,0px)
	}
}
@keyframes fade {
	0% {
		opacity:0
	}

	100% {
		opacity:1
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: scale(0.6);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}