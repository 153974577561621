@import "../../../scss/ThemeVariables.module.scss";
.Option {
	font-size: 1rem;
	font-family: var(--Parag_Font);
	background-color: var(--Select_BackgroundColor)
}

.Select {
	width: auto;
	height: auto;
	background-color: var(--Select_BackgroundColor)
}