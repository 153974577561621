

.SmallImage {
	max-height: 64px;
	max-width: 64px;
}

.MediumImage {
	max-height: 128px;
	max-width: 128px;
}
.LargeImage {
	max-height: 512px;
	max-width: 512px;
}

.BigImage {
	width: 100%;
	height: auto;
}
.SmallerImage{
	width:32px;
	height:32px;
}

.FixedHeightSmallImage{
	max-height:64px;

}