@import "./ThemeVariables.module.scss";
.NormalText {
	font-size: 1.5rem;
	font-family: var(--Parag_Font)
}
.ButtonText {
	font-size: 1rem;
	font-family: var(--Parag_Font);
	color: var(--Button_Text_Color);
}
.ParagText {
	text-align: justify;
	text-justify: inter-word;
}

.TitleText {
	font-size: 2.5rem;
	font-family: var(--Title_Font)
}

.SubTitleText {
	font-size: 2rem;
	font-family: var(--Title_Font)
}

.BiggestText {
	font-size: 4rem;
	font-family: var(--Title_Font)
}

.BigText {
	font-size: 3rem;
	font-family: var(--Title_Font)
}

.SmallText {
	font-size: 0.5rem;
	font-family: var(--Parag_Font)
}
.HiddenLink{
	text-decoration:none;
}

.NoWrap{
	flex-wrap:nowrap;
}
.Centered{
	text-align:center;
}