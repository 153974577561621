@import "../../../scss/ThemeVariables.module.scss";
.Modal {
	min-width: 412px;
	
	z-index:100;
	position: fixed;
	width: 40%;
	
	padding: 20px;
	border-radius: 10px;
	left: 30%;
	top: 10%;
	border: 2px solid var(--Modal_Border);
	background-color: var(--Modal_Color);
	overflow: hidden;
}

.ModalContent {
	background-color: var(--Modal_Color);
	width: 100%;
	height: 100%;
	
	overflow-y: auto;
	overflow-x: hidden;
}
.Show{
	display:block
}
.Hide{
	display:none
}
.Height {
	height: 80%;
}
.Fit {
	height: fit-content;
}