.FlexColumnCenter {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content:center;
}

.FlexRowCenter {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content: center;
}
.FlexColumnCenterTop {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content: flex-start;
}
.FlexRowEvenly {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content: space-evenly;
}
.FlexRowStart {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: flex-start;
	justify-items: flex-start;
	justify-content: flex-start;
}
.FlexRowEnd {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: flex-start;
	justify-items: flex-start;
	justify-content: flex-end;
}
.FlexRowBetween {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: flex-start;
	justify-items: flex-start;
	justify-content: space-between;
}
.Wrap{
	flex-wrap:wrap;
}