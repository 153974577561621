@import "../../../scss/ThemeVariables.module.scss";
.CardContainer {
	justify-content: flex-Start;
	height: fit-content;
	padding: 10px;
	width:100%;
}

.CardPage {
	justify-content: flex-start;
}

.Card {
	display: flex;
	min-height: 98%;
	min-width: 98%;
	height: fit-content;
	background-color: var(--Card_Background)
}

.CardOuter {
	border-radius: 10px;
	margin: 10px;
	width: 90%;
	min-height: 95%;
	overflow-x: hidden;
	overflow-y: auto;
	box-shadow: var(--Shadow) 0px 4px 12px;
	border: 1px solid var( --Modal_Border);
	background-color: var( --Card_Background);
}

.CardTitleContainer {
	background-color: var( --Card_Background);
}

.MP{
	margin-left:10px;
	margin-right:10px;
	padding-left:10px;
	padding-right:10px;
}