
.DownloadSection {
	width: 100%;
	margin: 15px;
	justify-content: center;
}

.DownloadLink {
	text-decoration: none;
}
