
:root {
	--Navbar_Color: #87E0E0;
	--Navbar_Active_Button_Color: #BAEDE6;
	--Navbar_Hover_Button_Color: #DBF9F9;
	--Navbar_Button_Border_Color: #BAEDE6;
	--Navbar_Text_Color: #000000;
	--Navbar_ActiveText_Button_Color: #000000;
	--Navbar_Hover_Text_Button_Color: #000000;
	--Button_Text_Color: #000000;
	--Setting_Button_Border_Color: #9394a5;
	--Modal_Color: #A0E7E5;
	--Modal_Border: #87E0E0;
	
	--Home_Links_Color: #9394a5;
	--Shadow: #484b6a;
	--Text_Color: #484b6a;
	--Page_Background: #BAEDE6;
	--Card_Background: #CEF2EE;
	--Button_Color: #94BDCF;
	--Cool_Stamp: #1800F2;
	--New_Stamp: #F10000;
	--Cool_Stamp_BorderColor: #FFFFFF;
	--New_Stamp_BorderColor: #FFFFFF;
	--WelcomeAnimColor1: #FFFFFF;
	--WelcomeAnimColor2: #F10000;
	--WelcomeAnimColor3: #C2BD18;
	--WelcomeAnimColor4: #F8F7D2;
	--Select_BackgroundColor: #FFFFFF;
	--Parag_Font: old london, sans-serif;
	--Title_Font: old london, sans-serif;
	--Link_Font: old london, sans-serif;
	--NavBar_Font: old london, sans-serif;
	--base-stamp-rot: 0;
	--competence-basic-color: red;
	--competence-medium-color: yellow;
	--competence-good-color: #5aff00;
	--competence-excellent-color: Green;
	--small-border-color: black;
	--border-size: 1px;
	--color: red;
	--TXC:0;
	--TYC:0;
}
